<template>
  <div>
    <component :is="'b-card'">
      <b-row>
        <b-col cols="12" md="4" lg="4">
          <b-form-group :label="$t('Selected Category')" label-for="category">
            <b-form-input id="category" v-model="traitFeature.traitCategory.name" readonly/>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4" lg="4">
          <b-form-group :label="$t('Selected Feature')" label-for="featureName">
            <b-form-input id="featureName" v-model="traitFeature.name" readonly/>
          </b-form-group>
        </b-col>
      </b-row>
    </component>
    <component :is="'b-card'">
      <div class="d-flex align-items-center justify-content-end mb-2">
        <b-button
          variant="warning"
          class="mb-sm-0 mr-0 mr-sm-1"
          @click="addNewTrait()"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          Add New
        </b-button>
        <b-button
          variant="primary"
          class="mb-sm-0 mr-0 mr-sm-1"
          @click="save()"
          size="sm">
          <b-spinner
            v-if="$store.getters.getLoader"
            class="mr-1"
            small
            variant="light"
          />
          <feather-icon
            v-if="!$store.getters.getLoader"
            icon="SaveIcon"
            class="mr-50"
            size="16"
          />
          {{ $t('Save Changes') }}
        </b-button>
      </div>
      <b-table
        ref="refListTable"
        :fields="fields"
        :items="traitsList"
        :small="true"
      >

        <template #cell(traitGenotypeId)="data">
          <v-select
            v-model="data.item.traitGenotypeId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="genotypes"
            :reduce="(val) => val.id"
            :clearable="true"
            label="genotypeCode"
            input-id="traitGenotypeId"
            placeholder="Select Genotype"
          />
        </template>

        <template #cell(traitMarkerId)="data">
          <v-select
            v-model="data.item.traitMarkerId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="markers"
            :reduce="(val) => val.id"
            :clearable="true"
            label="markerCode"
            input-id="traitMarkerId"
            placeholder="Select Marker"
          />
        </template>

        <template #cell(traitEffectId)="data">
          <v-select
            v-model="data.item.traitEffectId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="effects"
            :reduce="(val) => val.id"
            :clearable="true"
            label="effect"
            input-id="traitEffectId"
            placeholder="Select Effect"
          />
        </template>

        <template #cell(rate)="data">
          <v-select
            v-model="data.item.rate"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="traitRates"
            :reduce="(val) => val.value"
            :clearable="true"
            label="label"
            input-id="rate"
            placeholder="Select Rate"
          />
        </template>

        <template #cell(mutantAllele)="data">
          <b-form-input id="mutantAllele" v-model="data.item.mutantAllele" />
        </template>
        <template #cell(normalAllele)="data">
          <b-form-input id="normalAllele" v-model="data.item.normalAllele" />
        </template>
        <template #cell(priority)="data">
          <b-form-input id="priority" v-model="data.item.priority" />
        </template>
        <template #cell(actions)="data">
          <div class="text-right">
            <feather-icon
              :id="`item-row-${data.item.id}-remove-icon`"
              @click="deleteItem(data.item)"
              icon="Trash2Icon"
              class="cursor-pointer mx-1 text-danger"
              size="16"
            />
          </div>
        </template>
      </b-table>
    </component>
  </div>
</template>

<script>
import { required, min, max } from '@validations';

// Alert fade
import { heightFade } from '@core/directives/animations';

// Options
import StaticOptions from '@/common/options/StaticOptions';

// Alert
import AlertService from '@/common/alert.service';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue';

export default {
  components: {
  },

  directives: {
    'height-fade': heightFade,
  },

  data() {
    return {
      required,
      min,
      max,

      traitRates: StaticOptions.traitRates,
      traitFeature: {},
      effects: [],
      markers: [],
      genotypes: [],

      traitsList: [],
      fields: [
        {
          key: 'traitMarkerId',
          label: 'Marker',
        },
        {
          key: 'traitGenotypeId',
          label: 'Genotype',
        },
        {
          key: 'traitEffectId',
          label: 'Effect',
        },
        {
          key: 'rate',
          label: 'rate',
        },
        {
          key: 'mutantAllele',
          label: 'Mut ALel',
          thStyle: { width: '5%' },
        },
        {
          key: 'normalAllele',
          label: 'Nor ALel',
          thStyle: { width: '5%' },
        }, 
        {
          key: 'priority',
          label: 'priority',
          thStyle: { width: '5%' },
        },
        { 
          key: 'actions', 
          class: 'text-right',
        },
      ],

      modalObject: {
        id: {},
        showModal: false,
      },
    };
  },

  methods: {
    addNewTrait() {
      this.traitsList.push({
        id: 0,
        traitFeatureId: null,
        traitEffectId: null,
        traitMarkerId: null,
        traitGenotypeId: null,
        priority: 0,
        rate: 1,
        mutantAllele: '',
        normalAllele: '',
        isActive: true,
      });
    },

    deleteItem(item) {
      AlertService.confirmDelete(this, () => {
        const index = this.traitsList.indexOf(item);
        if (index > -1) { 
          this.traitsList.splice(index, 1);
        }

        if (item.id) {
          this.$store
            .dispatch('traitManage/remove', { id: item.id })
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });
              } else {
                AlertService.error(this, response.message);
              }
            });
        } else {
          this.$toast({
            component: ToastificationSuccess,
            position: 'top-right',
            props: { text: '' },
          });
        }
      });
    },

    openFrequencyModal(item) {
      if (item.id === 0) {
        this.AlertService.warning(this, 'Please save changes first');
        return;
      }
      this.modalObject.id = item.id;
      this.modalObject.showModal = true;
    },

    fetchTraits() {
      const paramId = this.$router.currentRoute.params.featureId;
      if (parseInt(paramId, 10) !== 0) {
        this.$store
          .dispatch('traitManage/fetch', { id: paramId })
          .then(response => {
            if (response.statusCode === 200) {
              this.traitFeature = response.result.traitFeature;
              this.traitsList = response.result.traitList;
            } else {
              AlertService.error(this, response.message);
            }
          });
      }
    },

    loadEffects() {
      this.$store
        .dispatch('traitEffectManage/fetchAllList', {
          featureId: this.$router.currentRoute.params.featureId,
        })
        .then(response => {
          if (response.statusCode === 200) {
            this.effects = response.result;
          } else {
            AlertService.error(this, response.message);
          }
        });
    },

    loadMarkers() {
      this.$store
        .dispatch('traitMarkerManage/fetchAllList')
        .then(response => {
          if (response.statusCode === 200) {
            this.markers = response.result;
          } else {
            AlertService.error(this, response.message);
          }
        });
    },

    loadGenotypes() {
      this.$store
        .dispatch('traitGenotypeManage/fetchAllList')
        .then(response => {
          if (response.statusCode === 200) {
            this.genotypes = response.result;
          } else {
            AlertService.error(this, response.message);
          }
        });
    },

    save() {
      let control = true;
    
      this.traitsList.forEach(item => {
        item.traitFeatureId = this.traitFeature.id;

        if (!item.traitEffectId || !item.traitMarkerId || !item.traitGenotypeId || !item.rate) {
          control = false;
        }
      });

      if (control === false) {
        AlertService.warning(this, 'Please fill in all fields.');
        return;
      }

      this.$store
        .dispatch('traitManage/save', { traitList: this.traitsList })
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              });

              this.fetchTraits();
            } else {
              AlertService.error(this, response.message);
            }
          });
    },
  },

  created() {
    this.fetchTraits();
    this.loadEffects()
    this.loadMarkers();
    this.loadGenotypes();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
